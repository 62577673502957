@import '@/styles/_variable.scss';
@import '@/styles/./mixin.scss';

.landing {
    padding-top: 75px;
    padding-bottom: 9em;
    min-height: 93vh;
    padding-inline: 10px;
    background: url('/images/bg-02.png') no-repeat 100% 40%;
    background-size: 110% auto;
    transition: opacity 0.8s;
    opacity: 0;

    &.landingReady {
        opacity: 1;

        .logo span {
            width: 100%;
        }

        .content {
            animation: ani-3 1s cubic-bezier(0.68, -0.12, 0, 1.15);
            animation-delay: 0.5s;
            animation-fill-mode: both;
            opacity: 0;
        }

        nav {
            a {
                animation: ani-2 1s cubic-bezier(0.68, -0.12, 0, 1.15);
                animation-delay: 0.9s;
                animation-fill-mode: both;
                opacity: 0;

                &:nth-child(2) {
                    animation-delay: 1.1s;
                }

                &:nth-child(3) {
                    animation-delay: 1.3s;
                }
            }
        }
    }

    .logo {
        margin-block-end: 0.61em;
        position: relative;

        span {
            display: block;
            overflow: hidden;
            width: 0;
            transition: width 1s cubic-bezier(0.68, -0.12, 0, 1.15);
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            line-height: 1;
        }

        img {
            position: absolute;
            max-width: unset;
            height: 100%;
            left: 0;
            top: 0;
        }

        &:before {
            content: '';
            display: block;
            padding-bottom: 24.8%;
        }
    }

    .content {
        padding: 1.3846em;
        background-color: var(--color-white);
        border-radius: var(--size-12);
        display: flex;
        flex-direction: column;
        row-gap: var(--size-12);
        margin-inline: 6px;
        margin-block-end: 1.46em;
    }

    nav {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        --target-color: var(--color-yellow);
        gap: 1.0769em;

        a {
            box-shadow: var(--box-shadow);
            background-color: var(--target-color);
            color: var(--color-white);
            text-decoration: none;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            font-size: 2em;
            line-height: 1.1;
            font-weight: 300;
            border-radius: 0.461em;
            padding-inline: 0.384em;
            padding-block-start: 0.1923em;
            padding-block-end: 0.31em;

            span {
                flex-grow: 1;
            }

            svg {
                --circle-color: var(--color-white);
                --arrow-color: var(--target-color);
                block-size: 1.1538em;
                inline-size: 1.1538em;
            }
        }
    }
}


.loading {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    padding-inline: var(--size-20);
    padding-block-end: var(--size-18);
    background-color: var(--color-grayish-orange);
    overflow: auto;
    color: var(--color-black);

    @include pad-l {

        &::-webkit-scrollbar {
            width: 0px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    @include pad-l {
        max-width: var(--page-max-size);
        left: 0;
        right: 0;
        margin-inline: auto;
    }

    >div {
        display: flex;
        row-gap: 1.53em;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;
    }

    .loadingTopLogo {
        display: flex;
        justify-content: flex-end;
        overflow: hidden;
        position: relative;

        i {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: var(--color-grayish-orange);
        }

        img {
            inline-size: 4.6923076em;
        }
    }

    .loadinglogo {
        display: flex;
        justify-content: center;
        opacity: 0;

        img {
            max-width: 95%;
        }
    }

    .loadingContent {
        padding: 1.3846em;
        background-color: var(--color-white);
        border-radius: var(--size-12);
        text-align: center;
        display: flex;
        flex-direction: column;
        row-gap: var(--size-12);
        box-shadow: var(--box-shadow);
        margin-inline: 2.076em;
        opacity: 0;
    }

    .loadingLogos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-inline: 2.076em;
        column-gap: var(--size-12);

        label {
            font-family: var(--font-josefinslab);
            font-weight: 600;
            font-size: 1.384em;
            line-height: 0.833;
            max-inline-size: 4em;
        }

        figure {
            &:nth-child(2) {
                opacity: 0;
            }

            img {
                max-block-size: 3.30em;
            }

            &:last-child {
                opacity: 0;

                img {
                    max-block-size: 1.923em;
                }
            }
        }
    }

    .loadingFooter {
        text-align: center;
        font-size: 0.846em;
        font-weight: 350;
        line-height: 1.363;
        font-family: var(--font-avenir);
        animation: ani-3 1s cubic-bezier(0.68, -0.12, 0, 1.15);
        animation-delay: 2s;
        animation-fill-mode: both;
        opacity: 0;
    }

    .enterBtn {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        border: none;
        background: none;
        font-size: 2em;
        line-height: 1.1;
        font-weight: 300;
        text-transform: uppercase;
        color: var(--color-black);

        svg {
            --circle-color: var(--color-green);
            --arrow-color: var(--color-dark-gray);
            block-size: 1.538em;
            inline-size: 1.538em;
        }
    }

    &.loadingend {
        animation: ani-5 1.5s cubic-bezier(0.68, -0.12, 0, 1.15);
        animation-fill-mode: both;
        animation-delay: 0.5s;

        .loadingContent {
            opacity: 1;
            animation: ani-4 0.4s cubic-bezier(0.68, -0.12, 0, 1.15);
        }
    }
    header {
        opacity: 0;
        z-index: 3;
    }

    &.loadingStart {
        .loadinglogo {
            animation: ani-3 1s cubic-bezier(0.68, -0.12, 0, 1.15);
            animation-delay: 0.25s;
            animation-fill-mode: both;
        }

        header {
            animation: ani-7 1s cubic-bezier(0.68, -0.12, 0, 1.15);
            animation-delay: 0.25s;
            animation-fill-mode: both;
        }

        .loadingContent {
            animation: ani-3 1s cubic-bezier(0.68, -0.12, 0, 1.15);
            animation-delay: 0.5s;
            animation-fill-mode: both;
        }

        .loadingTopLogo {
            i {
                animation: ani-1 0.5s ease-in-out;
                animation-fill-mode: both;
            }
        }

        .loadingLogos {

            label {
                animation: ani-2 1s cubic-bezier(0.68, -0.12, 0, 1.15);
                animation-delay: 1s;
                animation-fill-mode: both;
            }

            figure {
                &:nth-child(2) {
                    animation: ani-2 1s cubic-bezier(0.68, -0.12, 0, 1.15);
                    animation-delay: 1.3s;
                    animation-fill-mode: both;
                }

                &:last-child {
                    animation: ani-2 1s cubic-bezier(0.68, -0.12, 0, 1.15);
                    animation-delay: 1.6s;
                    animation-fill-mode: both;
                }
            }
        }
    }
}

@keyframes ani-1 {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(100%);
    }
}

@keyframes ani-2 {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes ani-3 {
    0% {
        transform: translateY(50%);
        opacity: 0;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes ani-4 {

    0%,
    100% {
        transform: 1;
        transform: scale(1);
    }

    50% {
        transform: 1;
        transform: scale(1.04);
    }
}

@keyframes ani-5 {
    0% {
        transform: translateY(0%);
        opacity: 1;
    }

    100% {
        transform: translateY(-60%);
        opacity: 0;
    }
}

@keyframes ani-6 {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
}
@keyframes ani-7 {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}